import React from 'react'
import { Link } from 'gatsby'
import * as styles from './psychologist_page.module.scss'
import { Layout } from '../../layouts'
import SiteTitle from '../../components/Blocks/SiteTitle/SiteTitle'
import { CONTENT } from '../../content'
import Seo from '../../components/seo'
import Presentation from '../../components/Blocks/Presentation/Presentation'
import BreadcrumbList from '../../components/BreadcrumbList/BreadcrumbList'
import BreadcrumbItem from '../../components/BreadcrumbList/BreadcrumbItem'

const PsychologistPage = () => (
  <Layout>
    <>
      <Seo
        title="Psychologue Clinicien - Psychothérapeute"
        description="Découvrez les similtudes et les différences entre les fonctions de psychologue clinicien et de psychothérapeute"
      />

      <SiteTitle tag="span" />

      <div className="wrapper">
        <Presentation />
        <BreadcrumbList>
          <>
            <BreadcrumbItem position={1}>
              <Link className="link" itemProp="item" to="/">
                <span itemProp="name">Accueil</span>
              </Link>
            </BreadcrumbItem>
            ›
            <BreadcrumbItem position={2}>
              <Link className="link" itemProp="item" to="/presentation">
                <span itemProp="name">Présentation</span>
              </Link>
            </BreadcrumbItem>
            ›
            <BreadcrumbItem position={3}>
              <span itemProp="name">
                Psychologue Clinicien - Psychothérapeute
              </span>
            </BreadcrumbItem>
          </>
        </BreadcrumbList>
      </div>
    </>
  </Layout>
)

export default PsychologistPage
