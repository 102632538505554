import React from 'react'
import * as styles from './Presentation.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { CONTENT } from '../../../content'

const Presentation = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "coral.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={styles.Presentation}
      Tag="section"
      fluid={image?.sharp?.fluid}
      style={{ backgroundSize: 'contain' }}
      id="presentation"
    >
      <div className={`wrapper ${styles.section_wrapper}`}>
        <section className={styles.section}>
          <header>
            <h2>{CONTENT.PRESENTATION.PSYCHOLOGIST.title}</h2>
          </header>

          {CONTENT.PRESENTATION.PSYCHOLOGIST.content.map((content, index) => (
            <p key={index}>{content}</p>
          ))}
        </section>

        <section className={styles.section}>
          <header>
            <h2>{CONTENT.PRESENTATION.PSYCHOTHERAPIST.title}</h2>
          </header>

          {CONTENT.PRESENTATION.PSYCHOTHERAPIST.content.map(
            (content, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
            ),
          )}
        </section>
      </div>
    </BackgroundImage>
  )
}

export default Presentation
